import axios from 'axios';
import { appConfig } from 'src/config';
import { checkError } from './common';
import { errorHandler } from './common';
import toast from 'react-hot-toast';
import { Typography } from '@mui/material';

const restPrefix = appConfig.restPrefix;
const versionNumber = appConfig.versionNumber;
const http = axios.create({
  baseURL: restPrefix
});
export const getSnippets = () => {
  return http
    .get('/procs/getMethodDescribe/', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
      }
    })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getCommandList = () => {
  return http
    .get('/procs/commandList/', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
      }
    })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getProcessDesign = () => {
  return http
    .get('DESIGNER/GET/', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};




export const copyStep = (currentProc, currentRev, currentStep, newProc, newStep) => {
  return postCall(
    'DESIGNER/step/clone/' + currentProc + '/' + currentRev + '/' + currentStep + '/' + newStep + '/' + newProc + '/',
    null
  );
};


// METODO IN BE E' COMMENTATO
export const copyProces = (currentProc, currentRev, newProc) => {
  return postCall(
    'DESIGNER/proces/clone/' + currentProc + '/' + currentRev + '/' + newProc + '/',
    null
  );
};

export const getAvatar = async userName => {
  return await getCall(
    'avatar/' + userName + '/',
    null
  );
};

export const procesFromVersion = (proc, rev) => {
  return postCall(
    'DESIGNER/GET/PROCES' + proc + '/' + rev + '/',
    null
  );
};

// export const copyStep = (currentProc, currentStep, newStep, newProc) => {
//   return postCall(
//     'DESIGNER/CLONESTEP/' + currentProc + '/' + currentStep + '/' + newStep + '/' + newProc + '/',
//     null
//   );
// };

export const testSQL = sql => {
  return http
    .post('testSQL/', null, {
      params: {
        sql: sql
      }
    })
    .then(response => {
      const risposta = response.data;

      // const jwt = response.jwt;
      // if (jwt !== "") {
      //   setCookie("jwt", jwt, 2);
      // }
      return risposta;
    })
    .catch(error => errorHandler(error));
};

const deleteCall = (url) => {
  return http
    .delete(url, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt != undefined && jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};
const getCall = (url) => {
  return http
    .get(url, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt != undefined && jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

const postCall = (url, data, lastUpdate) => {
  return http
    .post(url, data, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber,
        lastUpdate: lastUpdate === undefined ? "" : lastUpdate
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt != undefined && jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const saveVariable = (codProc, codStep, variable, lastUpdate) => {
  return postCall(
    '/DESIGNER/saveVariable/' + codProc + '/' + codStep + '/',
    variable,
    lastUpdate
  );
};


export const getAllMessageLanguageFromLabel = (label) => {
  return http
    .get('translationsByLabel/', {
      params: {
        label: label
      },
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    });
};

export const getHeadersSQL = (sql) => {
  return http
    .post('headersSQL/', {
      sql: sql
    })
    .then(response => {
      const risposta = response.data;
      return risposta;
    })
    .catch(error => errorHandler(error));
};

export const saveAllMessageLanguagesForLabel = (label, translations) => {
  return postCall(
    'saveAllMessageLanguagesForLabel/' + label + "/",
    translations
  );
};

export const deleteSystemVariable = (codVariable, lastUpdate) => {
  return deleteCall('/DESIGNER/deleteSystemVariable/' + codVariable, lastUpdate);
};
export const deleteVariable = (codProc, codStep, codVariable, lastUpdate) => {
  return deleteCall(
    '/DESIGNER/deleteVariable/' +
    codProc +
    '/' +
    codStep +
    '/' +
    codVariable +
    '/', lastUpdate
  );
};

export const restHandler = async (json, messageok) => {
  const server = await json.server;

  if (server == 200) {
    toast.success(<Typography>{messageok}</Typography>);
    //   getDesigner();
  } else {
    if (server == 406) {
      setErrMsg(json.body.response);
      setShowErr(true);
    } else {
      const msg = json.text || json.response;
      setErrMsg(msg);
      setShowErr(true);
    }
  }
};

export const saveSystemVariable = (sysVariables) => {
  return postCall('/DESIGNER/sysvariables/save/', sysVariables);
};

export const preview = (procs, step) => {
  return postCall('/DESIGNER/preview/' + step, procs);
};

export const saveProcs = (processo, lastUpdate) => {
  processo.errors = undefined;
  return postCall('/DESIGNER/proces/save', processo, lastUpdate);
};

export const saveMessages = messages => {
  return postCall('/DESIGNER/messages/save', messages);
};

export const saveProjects = projects => {
  return postCall('/DESIGNER/projects/save', projects);
};

export const avatarById = async id => {
  return await postCall('/avatarById/' + id);
};

export const saveStep = (codiceProcesso, step, lastUpdate) => {
  return postCall('/DESIGNER/saveStep/' + codiceProcesso, step, lastUpdate);
};

export const deleteProcessApi = (procCode) => {
  return deleteCall('DESIGNER/proces/delete/' + procCode);
};

export const deleteStepOfProcessApi = (processCode, stepCode, lastUpdate) => {
  return deleteCall(
    'DESIGNER/DELETE/processes/' + processCode + '/step/' + stepCode, lastUpdate
  );
};

export const saveMessage = (msg, lastUpdate) => {
  return postCall('/DESIGNER/saveMessage/', msg, lastUpdate);
};

export const newMessage = newMessage => {
  return postCall('DESIGNER/CREATE/messages', newMessage);
};

// export const deleteMessage = (msgCode, lastUpdate) => {
//   return deleteCall('DESIGNER/DELETE/messages/' + msgCode, lastUpdate);
// };

export const copyMessage = (procCode, newCode) => {
  if (newCode === undefined || newCode === '') return undefined;
  return postCall(
    'DESIGNER/CLONE/messages/' + procCode + '/' + newCode + '/',
    {}
  );
};

export const saveVariables = variables => {
  return http
    .post('/variables', variables, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getProcesses = () => {
  return getCall('DESIGNER/proces/list');
};
export const getAllProcesses = () => {
  return getCall('DESIGNER/get/processes');
};

export const getProcesseswithVersion = (codice, version) => {
  return http
    .get(`DESIGNER/proces/get/${codice}/${version}`, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getStepAPI = (codice, step) => {
  return http
    .get(`DESIGNER/step/get/${codice}/${step}`, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};


export const getMenu = () => {
  return getCall('DESIGNER/menu/get');
};

export const getMessages = () => {
  return getCall('DESIGNER/messages/get');
};

export const getProjects = () => {
  return getCall('DESIGNER/projects/get');
};

export const getSysVariables = () => {
  return getCall('DESIGNER/sysvariables/get');
};
export const saveMenu = menu => {
  return postCall('DESIGNER/menu/save', menu);
};



//aggiungere rest per getSettings
export const getSettings = () => {
  return getCall('DESIGNER/settings/get');
};

export const saveSettings = settings => {
  return postCall('DESIGNER/settings/save', settings);
};

export const getModules = () => {
  return getCall('DESIGNER/modules/get');
};

export const getPermissions = () => {
  return getCall('DESIGNER/permissions/get');
};

export const saveOrganization = organization => {
  const root = organization.root;
  return http
    .post(
      'DESIGNER/organization',
      { root },
      {
        headers: {
          jwt: window.localStorage.getItem('accessToken'),
          versionNumber,
          lastUpdate: organization.lastUpdate === undefined ? "" : organization.lastUpdate
        }
      }
    )
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getOrganization = () => {
  return http
    .get('DESIGNER/organization', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getNodes = () => {
  return http
    .get('DESIGNER/nodes', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const saveNodes = nodes => {
  return http
    .post(
      'DESIGNER/nodes',
      nodes,
      {
        headers: {
          jwt: window.localStorage.getItem('accessToken'),
          versionNumber,
          lastUpdate: nodes.lastUpdate === undefined ? "" : nodes.lastUpdate
        }
      }
    )
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

/* export const get = () => {
  return http
    .get('auth/get/nodes', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
}; */



/* export const getSettings = () => {
  return http
    .get('auth/get/settings', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const saveSettings = settings => {
  return http
    .post(
      'auth/set/settings',
      settings,
      {
        headers: {
          jwt: window.localStorage.getItem('accessToken'),
          versionNumber,
          lastUpdate: settings.lastUpdate === undefined ? "" : settings.lastUpdate
        }
      }
    )
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
}; */



export const getDiagram = id => {
  return http
    .get('/camunda/xml/taskId/' + id)
    .then(response => {
      if (response.data.response.bpmn20Xml) {
        return response.data.response.bpmn20Xml;
      } else {
        return response;
      }
    })
    .catch(error => errorHandler(error));
};

export const getExportProcs = () => {
  return http
    .get('/DESIGNER/EXPORT/', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      return resp;
    })
    .catch(error => errorHandler(error));
};

export const importProcs = data => {
  return http
    .post('/DESIGNER/IMPORT', data, {
      headers: {
        jwt: localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.status;

      return resp;
    })
    .catch(error => errorHandler(error));
};

export const expireCache = () => {
  return http
    .post('/expirecache')
    .then(response => {
      return response;
    })
    .catch(error => error);
};

export const getAllTranslations = () => {
  return http
    .get('/allTranslations', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getPluginsByType = (type) => {
  return http
    .get('/pluginsByType/' + type + '/', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getAllPlugins = () => {
  return http
    .get('/plugins', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getTranslationsFilePath = () => {
  return http
    .get('/translationFilePath', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
      }
    })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

// export const saveAllMessageLanguagesForLabel = (label, traduzioni) => {
//   return postCall(
//     'saveAllMessageLanguagesForLabel/' + label + "/",
//     traduzioni

//   );

export const searchKeysWithLike = (label) => {
  return http
    .get('/searchKeysWithLike', {
      params: {
        label
      },
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const translationByLabel = (label) => {
  return http
    .get('/translationsByLabel', {
      params: {
        label
      },
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};


export const saveAllTranslations = allTranslations => {
  return http
    .post(
      '/saveAllMessageLanguagesForLabel',
      allTranslations,
      {
        headers: {
          jwt: window.localStorage.getItem('accessToken'),
          versionNumber
        }
      }
    )
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));

};


export const avaibleLanguages = () => {
  return http
    .get('/avaibleLanguages', {
      headers: {
        jwt: "fake",
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const deploy = () => {
  return http
    .post('/deploy')
    .then(response => {
      return response;
    })
    .catch(error => error);
};


