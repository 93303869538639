import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { appConfig } from 'src/config';
const customPath = appConfig.customPath;



const GuestGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();


  if (isAuthenticated) {
    if (user.dbInfo) {
      return (
        <Redirect
          to={`${customPath}/app/reports/dashboardProcs/${user.dbInfo.codProc}/${user.dbInfo.codStep}`}
        />
      );
    } else {
      return <Redirect to={`${customPath}/app/reports/dashboardProcs`} />;
    }
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
