import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { appConfig } from 'src/config';
import { getMessaging, onMessage } from 'firebase/messaging';
import { notificationsHeader } from 'src/api/notifications-api';
import { ToastContainer, toast } from 'react-toastify';

const initialState = {
  needUpdate: false
};
const slice = createSlice({
  name: 'needUpdate',
  initialState,
  reducers: {
    getNeedUpdateReducer(state, action) {
      const { needUpdate } = action.payload;
      state.needUpdate = needUpdate;
    }
  }
});

export const reducer = slice.reducer;

export const setNeedUpdate = (needUpdate) => async dispatch => {
  dispatch(slice.actions.getNeedUpdateReducer({ needUpdate }));
}



export default slice;
