import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'axios';
import { setCookie } from 'src/restBox/restBox';
import useSettings from 'src/hooks/useSettings';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { appConfig } from 'src/config';
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken
} from 'firebase/messaging';
import { saveFirebaseToken } from 'src/api/functions-api';
import { vapidkey } from 'src/config';
import { firebaseConfig } from 'src/config';
import { initializeApp } from 'firebase/app';
import { checkError } from 'src/api/common';
import { setNeedUpdate } from 'src/slices/needUpdate'
import { useDispatch, useSelector } from 'src/store';
import moment from 'moment';
import i18next from 'i18next';
import Cookies from 'js-cookie';
const customPath = appConfig.customPath

if (appConfig.enablePushNotifications) {
  initializeApp(firebaseConfig);
}

const baseurlRest = appConfig.restPrefix;
const id_frontend = appConfig.idFrontEnd;
const versionNumber = appConfig.versionNumber;

const instance = axios.create({
  baseURL: baseurlRest,
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
    'Access-Control-Expose-Headers': baseurlRest,
    versionNumber
  }
});

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = accessToken => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
  // return true;
};

const setSession = accessToken => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    //axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common.Authorization = accessToken;
  } else {
    localStorage.removeItem('accessToken');
    //delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN':
    case 'UPDATEUSER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'UPDATEAVATAR': {
      const { avatar } = action.payload;
      return {
        ...state, user: { ...state.user, avatar }
      }
    }
    default: {
      return { ...state };
    }
  }
};

const handleToken = async token => {
  const resp = await saveFirebaseToken(token);

};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  anonymousLogin: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  removeAvatar: () => Promise.resolve(),
  setAvatar: () => Promise.resolve(),
  TestToken: () => Promise.resolve(),
  modifyAvatar: () => Promise.resolve()
});

const isDashboardPresent = menu => {
  let dbInfo = null;
  if (menu) {
    menu.root.map(m => {
      if (m.children) {
        m.children.map(c => {
          if (c.page == `/reports/dashboardProcs` || c.page == `reports/dashboardProcs`) {
            dbInfo = {
              codProc: c.codeProcess,
              codStep: c.codeStep
            };
          }
        });
      }
    });
  }
  return dbInfo;
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { settings, saveSettings } = useSettings();
  const { t } = useTranslation();
  const _dispatch = useDispatch();


  const setInternalSettings = (theme, lang) => {
    saveSettings({
      theme: theme || appConfig.defaultTheme,
      lang: lang || "it",
      direction: lang == 'ar' ? 'rtl' : 'ltr'
    });
    i18next.changeLanguage(lang || "it");
    moment.locale(lang || "it")
  }

  const login = async (email, password, tokenId) => {
    const response = await instance.post('/login', null, {
      params: {
        id: email,
        password: password,
        Gjwt: tokenId,
        idFrontend: id_frontend
      }
    });
    if (response.data.server != 200) {
      checkError(response)
    }

    const { jwt } = response.data;
    const avatar = await instance.get('/avatar', {
      headers: { jwt: jwt }
    });


    const dbInfo = isDashboardPresent(response.data.response.menu);
    const user = {
      avatar: avatar.data,
      email: response.data.response.mail,
      id: response.data.response.id,
      name: response.data.user,
      tier: response.data.response.permission,
      menu: response.data.response.menu,
      name: response.data.response.name,
      userName: response.data.response.userName,
      logo: response.data.response.logo,
      logoPosition: response.data.response.logoPosition,
      isGoogle: tokenId ? true : false,
      debugMode: response.data.response.debugMode,
      canChangePwd: response.data.response.canChangePwd,
      dbInfo: dbInfo,
      listPermission: response.data.response.listPermission,
      permissionsNodes: response.data.response.permissionsNodes,
      node: response.data.response.node,
      companyDescription: response.data.response.companyDescription
    };
    setSession(jwt);

    setInternalSettings(response.data.response.theme, response.data.response.lang)



    if (appConfig.enablePushNotifications) {
      const messaging = getMessaging();
      getToken(messaging, { vapidKey: vapidkey })
        .then(currentToken => {
          if (currentToken) {
            handleToken(currentToken);
          } else {
            console.log(
              'No registration token available. Request permission to generate one.'
            );
          }
        })
        .catch(err => {
          console.log('An error occurred while retrieving token. ', err);
        });
    }

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const anonymousLogin = async () => {
    const response = await instance.post('/anonymousLogin', null, {
      params: {
        idFrontend: id_frontend
      }
    });
    if (response.data.server != 200) {
      checkError(response)
    }

    const { jwt } = response.data;
    const avatar = await instance.get('/avatar', {
      headers: { jwt: jwt }
    });

    setCookie('jwt', jwt);

    const dbInfo = isDashboardPresent(response.data.response.menu);
    const user = {
      avatar: avatar.data,
      email: response.data.response.mail,
      id: response.data.response.id,
      name: response.data.user,
      tier: response.data.response.permission,
      menu: response.data.response.menu,
      name: response.data.response.name,
      logo: response.data.response.logo,
      logoPosition: response.data.response.logoPosition,
      isGoogle: false,
      debugMode: response.data.response.debugMode,
      canChangePwd: response.data.response.canChangePwd,
      dbInfo: dbInfo,
      listPermission: response.data.response.listPermission,
      permissionsNodes: response.data.response.permissionsNodes,
      node: response.data.response.node,
      companyDescription: response.data.response.companyDescription
    };
    setSession(jwt);

    setInternalSettings(response.data.response.theme, response.data.response.lang)

    if (appConfig.enablePushNotifications) {
      const messaging = getMessaging();
      getToken(messaging, { vapidKey: vapidkey })
        .then(currentToken => {
          if (currentToken) {
            handleToken(currentToken);
          } else {
            console.log(
              'No registration token available. Request permission to generate one.'
            );
          }
        })
        .catch(err => {
          console.log('An error occurred while retrieving token. ', err);
        });
    }

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const updateUser = async (userId, body) => {
    const update = await instance.post(`/LOGIN/UPDATEUSER/${userId}`, body, {
      headers: {
        'content-type': 'application/json',
        jwt: localStorage.getItem('accessToken')
      }
    });

    const response = await instance.post('/TestToken', null, {
      headers: { jwt: localStorage.getItem('accessToken') }
    });

    const { jwt } = response.data;

    const avatar = await instance.get('/avatar', {
      headers: { jwt: jwt }
    });
    const dbInfo = isDashboardPresent(response.data.response.menu);

    const user = {
      avatar: avatar.data,
      email: response.data.response.mail,
      id: response.data.response.id,
      name: response.data.user,
      tier: response.data.response.permission,
      menu: response.data.response.menu,
      name: response.data.response.name,
      logo: response.data.response.logo,
      logoPosition: response.data.response.logoPosition,
      isGoogle: response.data.response.externalAvatar ? true : false,
      debugMode: response.data.response.debugMode,
      permissionsNodes: response.data.response.permissionsNodes,
      canChangePwd: response.data.response.canChangePwd,
      dbInfo: dbInfo,
      node: response.data.response.node,
      companyDescription: response.data.response.companyDescription
    };
    setSession(jwt);

    setInternalSettings(response.data.response.theme, response.data.response.lang)

    dispatch({
      type: 'UPDATEUSER',
      payload: {
        user
      }
    });
  };

  const setAvatar = async file => {
    var dataFile = new FormData();
    dataFile.append('file', file);

    const addAvatar = await instance.post(`/avatar`, dataFile, {
      headers: {
        'content-type': 'multipart/form-data',
        jwt: localStorage.getItem('accessToken')
      }
    });

    if (addAvatar.data.server == 200) {
      toast.success(t('picUploaded'));
      const avatar = await instance.get('/avatar', {
        headers: { jwt: localStorage.getItem('accessToken') }
      });

      dispatch({
        type: 'UPDATEAVATAR',
        payload: {
          avatar: avatar.data || ''
        }
      });
    }
  };

  const removeAvatar = async () => {
    const deleteAvatar = await instance.delete(`/avatar`, {
      headers: {
        'content-type': 'application/json',
        jwt: localStorage.getItem('accessToken')
      }
    });

    if (deleteAvatar.data.server == 200) {

      const avatar = await instance.get('/avatar', {
        headers: { jwt: localStorage.getItem('accessToken') }
      });

      dispatch({
        type: 'UPDATEAVATAR',
        payload: {
          avatar: avatar.data || ''
        }
      });
    }
  };

  const modifyAvatar = async (file) => {
    const deleteAvatar = await instance.delete(`/avatar`, {
      headers: {
        'content-type': 'application/json',
        jwt: localStorage.getItem('accessToken')
      }
    });

    if (deleteAvatar.data.server == 200) {
      var dataFile = new FormData();
      dataFile.append('file', file);
      const addAvatar = await instance.post(`/avatar`, dataFile, {
        headers: {
          'content-type': 'multipart/form-data',
          jwt: localStorage.getItem('accessToken')
        }
      });
      if (addAvatar.data.server == 200) {

        const avatar = await instance.get('/avatar', {
          headers: { jwt: localStorage.getItem('accessToken') }
        });
        toast.success(t('picUploaded'));
        dispatch({
          type: 'UPDATEAVATAR',
          payload: {
            avatar: avatar.data || ''
          }
        });
      }
    }
  };

  const logout = () => {
    Cookies.remove("jwt")
    Cookies.remove("spidAccessToken")
    Cookies.remove("spidRefreshToken")
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  // const register = async (email, name, password, lingua, token) => {
  //    
  //   const response = await instance.post('/LOGIN/NEWUSER', null, {
  //     params: {
  //       email: email,
  //       firstName: name,
  //       password: password,
  //       language: lingua,
  //       token: token,
  //       permission: profilo_specifico
  //     }
  //   });
  //   // return response;
  //   const { accessToken, user } = response.data;

  //   window.localStorage.setItem('accessToken', accessToken);

  //   dispatch({
  //     type: 'REGISTER',
  //     payload: {
  //       user
  //     }
  //   });
  // };

  const TestToken = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        const response = await instance.post('/TestToken', null, {
          headers: { jwt: accessToken }
        });
        setSession(response.data.jwt);
        // let img =
        //   response.data.response.avatar != null
        //     ? response.data.response.avatar
        //     : '/static/images/avatars/' +
        //       response.data.response.name +
        //       '.png';

        const avatar = await instance.get('/avatar', {
          headers: { jwt: window.localStorage.getItem('accessToken') }
        });
        const dbInfo = isDashboardPresent(response.data.response.menu);
        const user = {
          avatar: avatar.data,
          email: response.data.response.mail,
          id: response.data.response.id,
          name: response.data.user,
          tier: response.data.response.permission,
          menu: response.data.response.menu,
          name: response.data.response.name,
          logo: response.data.response.logo,
          logoPosition: response.data.response.logoPosition,
          isGoogle: response.data.response.externalAvatar ? true : false,
          debugMode: response.data.response.debugMode,
          canChangePwd: response.data.response.canChangePwd,
          dbInfo: dbInfo,
          listPermission: response.data.response.listPermission,
          permissionsNodes: response.data.response.permissionsNodes,
          debugMode: response.data.response.debugMode,
          node: response.data.response.node,
          companyDescription: response.data.response.companyDescription
        };

        setInternalSettings(response.data.response.theme, response.data.response.lang)


        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: true,
            user
          }
        });
      } else {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      // console.error(err.response.data);
      if (err.response) {
        if (err.response.data.server == 501) {
          _dispatch(setNeedUpdate(true));
        } else {
          _dispatch(setNeedUpdate(false));
        }
      }

      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  useEffect(() => {
    // const initialise = async () => {
    //   try {
    //     const accessToken = window.localStorage.getItem('accessToken');

    //     if (accessToken && isValidToken(accessToken)) {
    //       const response = await instance.post('/TestToken', null, {
    //         headers: { jwt: accessToken }
    //       });
    //       setSession(response.data.jwt);
    //       // let img =
    //       //   response.data.response.avatar != null
    //       //     ? response.data.response.avatar
    //       //     : '/static/images/avatars/' +
    //       //       response.data.response.name +
    //       //       '.png';

    //       const avatar = await instance.get('/avatar', {
    //         headers: { jwt: window.localStorage.getItem('accessToken') }
    //       });
    //       const dbInfo = isDashboardPresent(response.data.response.menu);
    //       const user = {
    //         avatar: avatar.data,
    //         email: response.data.response.mail,
    //         id: response.data.response.id,
    //         name: response.data.user,
    //         tier: response.data.response.permission,
    //         menu: response.data.response.menu,
    //         name: response.data.response.name,
    //         logo: response.data.response.logo,
    //         isGoogle: response.data.response.externalAvatar ? true : false,
    //         canChangePwd: response.data.response.canChangePwd,
    //         dbInfo: dbInfo
    //       };

    //       dispatch({
    //         type: 'INITIALISE',
    //         payload: {
    //           isAuthenticated: true,
    //           user
    //         }
    //       });
    //     } else {
    //       dispatch({
    //         type: 'INITIALISE',
    //         payload: {
    //           isAuthenticated: false,
    //           user: null
    //         }
    //       });
    //     }
    //   } catch (err) {
    //     console.error(err);
    //     checkError(err)
    //     dispatch({
    //       type: 'INITIALISE',
    //       payload: {
    //         isAuthenticated: false,
    //         user: null
    //       }
    //     });
    //   }
    // };

    TestToken();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        anonymousLogin,
        logout,
        // register,
        updateUser,
        removeAvatar,
        setAvatar,
        TestToken,
        modifyAvatar
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

