import React, { useState, useEffect, useLayoutEffect } from 'react';
import Slider from 'src/components/Slider/Slider';
import useSettings from 'src/hooks/useSettings';

import CookiesNotification from 'src/components/CookiesNotification';
import { appConfig } from 'src/config';

const display_slide = appConfig.displaySlide;
const display_cookie = appConfig.displayCookie;

export default function SliderOnStart() {
  const [openSlide, setOpenSlide] = useState(false);

  const { saveSettings, settings } = useSettings();

  useLayoutEffect(() => {
    if (!settings.sliderShown) {
      setOpenSlide(true);
    }
  }, []);

  const closeSlider = checked => {
    setOpenSlide(false);

    if (checked) {
      saveSettings({ sliderShown: true });
    }
  };

  if (display_slide == false || !openSlide) {
    return display_cookie == true ? <CookiesNotification /> : null;
  }

  return <Slider closeSlider={closeSlider} />;
}
