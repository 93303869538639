import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { getCommandList } from 'src/api/designer-api'

const initialState = {
  commandsList: []
};
const slice = createSlice({
  name: 'commandsList',
  initialState,
  reducers: {
    getCommandsList(state, action) {
      const { commandsList } = action.payload;
      state.commandsList = commandsList;
    }
  }
});

export const reducer = slice.reducer;


export const getCommandsList = () => async dispatch => {
  const response = await getCommandList();
  if (response) {
    let data = { commandsList: response.response };
    dispatch(slice.actions.getCommandsList(data));
  }
};

export default slice;
