import axios from 'axios';
import { appConfig } from 'src/config';
import { checkError } from './common';
import { errorHandler } from './common';

const restPrefix = appConfig.restPrefix;
const versionNumber = appConfig.versionNumber;
const http = axios.create({
  baseURL: restPrefix
});


export const getAllPlugins = () => {
  return http
    .get('/plugins', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

