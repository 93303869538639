/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  Icon as MuiIcon
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DomainIcon from '@mui/icons-material/Domain';
import PersonIcon from '@mui/icons-material/PersonOutline';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PieChartIcon from '@mui/icons-material/PieChart';
import Logo from 'src/components/Logo';
import BottomLogo from 'src/components/BottomLogo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { useTranslation } from 'react-i18next';
import { createSvgIcon } from '@mui/material/utils';
import { useHistory } from 'react-router';
import { OrganizationPopover } from './organization-popover';
import { updateNodePermission } from 'src/api/login-api';
import { constantCase } from 'change-case';
import { appConfig } from 'src/config';
import { useDispatch } from 'src/store';
import { getNotifications } from 'src/slices/notification';
import { getBoard } from 'src/slices/kanban';
import { useTheme } from "@mui/styles";

const linkLogo = appConfig.linkLogo;
const version = appConfig.versionNumber
const ambiente = appConfig.ambiente
const showPoweredBy = appConfig.showPoweredBy
const logoPosition = appConfig.logoPosition;
const customPath = appConfig.customPath

function renderNavItems({ items, pathname, depth = 1 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;
  if (item.children && item.children.length > 0) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.children
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
    // top: 64,
    height: '100%',
    zIndex: 2000,
  },
  desktopDrawer: {
    width: 256,
    // top: 64,
    zIndex: 2000,
    height: '100%'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));


const SelectorIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 14"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0C4.26519 5.66374e-05 4.51951 0.105451 4.707 0.293L7.707 3.293C7.88916 3.4816 7.98995 3.7342 7.98767 3.9964C7.9854 4.2586 7.88023 4.50941 7.69482 4.69482C7.50941 4.88023 7.2586 4.9854 6.9964 4.98767C6.7342 4.98995 6.4816 4.88916 6.293 4.707L4 2.414L1.707 4.707C1.5184 4.88916 1.2658 4.98995 1.0036 4.98767C0.741402 4.9854 0.49059 4.88023 0.305182 4.69482C0.119773 4.50941 0.0146042 4.2586 0.0123258 3.9964C0.0100473 3.7342 0.110842 3.4816 0.293 3.293L3.293 0.293C3.48049 0.105451 3.73481 5.66374e-05 4 0ZM0.293 9.293C0.480528 9.10553 0.734836 9.00021 1 9.00021C1.26516 9.00021 1.51947 9.10553 1.707 9.293L4 11.586L6.293 9.293C6.4816 9.11084 6.7342 9.01005 6.9964 9.01233C7.2586 9.0146 7.50941 9.11977 7.69482 9.30518C7.88023 9.49059 7.9854 9.7414 7.98767 10.0036C7.98995 10.2658 7.88916 10.5184 7.707 10.707L4.707 13.707C4.51947 13.8945 4.26516 13.9998 4 13.9998C3.73484 13.9998 3.48053 13.8945 3.293 13.707L0.293 10.707C0.105529 10.5195 0.000213623 10.2652 0.000213623 10C0.000213623 9.73484 0.105529 9.48053 0.293 9.293Z"
    />
  </svg>,
  'ChartSquareBar'
);

const NavBar = ({ onMobileClose, openMobile }) => {
  const [isDesigner, setisDesigner] = useState(false);
  const [openOrganizationsPopover, setOpenOrganizationsPopover] = useState(
    false
  );
  const [nodi, setNodi] = useState(null)
  const [reload, setReload] = useState(false)

  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { user, TestToken } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const menu = user.menu.root;
  const dbInfo = user.dbInfo;

  const currentNode = user.node
  const currentPermission = user.tier;
  const permissionsNodes = user.permissionsNodes;
  const userLogoPosition = user.logoPosition;

  const theme = useTheme()

  //  

  const isDarkTheme = theme.palette.mode == "dark" ? true : false


  const setNodiOnInit = () => {
    let nodes = []
    permissionsNodes.map(nodo => {
      const listPermission = nodo.codePermission.split(",").map(v => v.trim())
      listPermission.map(perm => {
        nodes.push({ nodo: nodo.codeNode, permission: perm })
      })
    })

    setNodi(nodes)

  };

  useEffect(() => {
    setNodiOnInit();
  }, []);

  useEffect(() => {
    if (reload) {
      if (user.dbInfo) {
        history.replace(`${customPath}/app/reports/dashboardProcs/${user.dbInfo.codProc}/${user.dbInfo.codStep}`);
      } else {
        history.replace(`${customPath}/app/reports/dashboardProcs`);
      }
    }
    return function cleanup() {
      setReload(false)
    }
  }, [reload]);






  const organizationsRef = useRef(null);

  const handleOpenOrganizationsPopover = () => {
    setOpenOrganizationsPopover(true);
  };
  const handleCloseOrganizationsPopover = () => {
    setOpenOrganizationsPopover(false);
  };

  const handleChangePermission = async (nodo, permission) => {
    //  
    // const permission = event.target.value;
    const resp = await updateNodePermission(nodo, permission);
    if (resp.server == 200) {
      await TestToken();
      dispatch(getNotifications(1, 1, ''));
      dispatch(getBoard(1, 1, ""))
      setReload(true)

    }
  };

  useEffect(() => {
    if (location.pathname.includes('ProcesDesign')) {
      setisDesigner(true);
    } else {
      setisDesigner(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);



  function renameCodeProcess(arr) {
    if (arr) {

      return arr.map((item) => {
        const { codeProcess, codeStep, children, description, page, pars, ...rest } = item;
        const newItem = { ...rest, proc: codeProcess, step: codeStep, title: description, href: `${customPath}/app/${page}/${codeProcess}/${codeStep}/${pars && pars != '' ? pars : ''}` };
        if (children) {
          newItem.children = renameCodeProcess(children);
        }
        return newItem;
      });
    } return []
  }

  const sections = renameCodeProcess(menu);
  function getSections() {
    let arrayMenu = [...sections];
    if (!dbInfo) {
      if (!appConfig.hideDashboard) {
        arrayMenu.push({
          items: [
            {
              title: t('dashboard'),
              icon: PieChartIcon,
              href: `${customPath}/app/reports/dashboardProcs`
            }
          ]
        });
      }
    }

    if (menu) {
      menu.map(m => {
        if (m.children) {
          let el = { subheader: m.description, items: [] };
          arrayMenu.push(el);
          m.children.map(c => {
            el.items.push({
              title: c.description,
              href:
                `${customPath}/app/` +
                c.page +
                '/' +
                c.codeProcess +
                '/' +
                c.codeStep +
                '/' +
                (c.pars && c.pars != '' ? c.pars : ''),
              icon: c.icon,
              page: c.page,
              proc: c.codeProcess,
              step: c.codeStep,
              pars: c.pars
            });
          });
        }
      });
    }

    return arrayMenu;
  }

  const content = (
    <>
      <PerfectScrollbar
        sx={{
          height: "100%",
          paddingTop: 'env(safe-area-inset-top)',
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
        options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}

        >
          <Hidden lgUp>
            <Box p={2} display="flex" justifyContent="center">
              {/* <RouterLink to="/">
              <Logo />
            </RouterLink> */}
            </Box>
          </Hidden>
          <Box p={2}>
            {logoPosition == "center" || userLogoPosition == 'center' ? (
              <Box display="flex" justifyContent="center">
                {linkLogo && linkLogo != "/" ?
                  <Link component="a" href={linkLogo} >
                    <Logo />
                  </Link> :
                  <RouterLink to={"/"}>
                    <Logo />
                  </RouterLink>}
              </Box>
            ) : (
              <Box display="flex" justifyContent="flex-start">
                {linkLogo && linkLogo != "/" ?
                  <Link component="a" href={linkLogo} >
                    <Logo />
                  </Link> :
                  <RouterLink to={"/"}>
                    <Logo />
                  </RouterLink>}
              </Box>
            )}
            <Box mt={2} >
              {/* <Link
              component={RouterLink}
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link> */}
              <Box mt={2} sx={{ px: "2px" }}>
                <Box
                  onClick={permissionsNodes && permissionsNodes.length ? handleOpenOrganizationsPopover : null}
                  ref={organizationsRef}
                  sx={{
                    alignItems: 'center',
                    backgroundColor: isDarkTheme ? "rgba(255, 255, 255, 0.04)" : '#f2f2f2',
                    cursor: permissionsNodes && permissionsNodes.length ? 'pointer' : "cursor",
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 1,
                    py: '14px',
                    borderRadius: 2
                  }}
                >
                  {permissionsNodes && permissionsNodes.length ?
                    <div>

                      <Box display="flex" sx={{ alignItems: "center", gap: '5px', mb: '4px' }}>
                        <DomainIcon fontSize="small" />
                        <Typography
                          variant="h5"
                          color="primary"
                          sx={{ padding: "0px 0 0", textAlign: "left" }}>
                          {constantCase(user.companyDescription || "-")}
                        </Typography>
                      </Box>

                      < Box display="flex" sx={{ alignItems: "center", gap: '5px', mb: '4px' }}>
                        <WorkOutlineIcon fontSize="small" />
                        <Typography
                          color="primary"
                          variant="body2"
                          sx={{ padding: "0px 0 0", textAlign: "left" }}
                        >
                          {currentNode}
                        </Typography>

                      </Box>
                      <Box display="flex" sx={{ alignItems: "center", gap: '5px' }}>
                        <PersonIcon fontSize="small" />
                        <Typography
                          color="primary"
                          variant="body2"
                          sx={{ padding: "0px 0 0", textAlign: "left" }}
                        >

                          {currentPermission}


                        </Typography>
                      </Box>
                    </div> :
                    <Box display="flex" sx={{ alignItems: "center", gap: '5px' }}>
                      <PersonIcon fontSize="small" />
                      <Typography
                        color="primary"
                        variant="body2"
                        sx={{ padding: "0px 0 0", textAlign: "left" }}
                      >

                        {currentPermission}


                      </Typography>
                    </Box>}
                  {permissionsNodes && permissionsNodes.length ? <SelectorIcon
                    style={{
                      color: 'primary',
                      width: 14,
                      height: 14
                    }}
                  /> : null}

                </Box>
              </Box>

            </Box>
          </Box >
          <Divider />
          <Box p={2} sx={{ flexGrow: 1 }}
          >
            {sections.map((section, index) => {
              const open = matchPath(section.children, {
                path: section.href,
                exact: false
              });
              return (
                <List disablePadding>
                  {section.children && section.children.length > 0 ? (
                    <NavItem
                      depth={0}
                      icon={section.icon}
                      // info={section.info}
                      key={index}
                      // open={Boolean(open)}
                      open={true}
                      title={section.title}
                    >
                      {renderNavItems({
                        items: section.children,
                        pathname: location.pathname
                      })}
                    </NavItem>
                  ) : (
                    <NavItem
                      depth={0}
                      href={section.href}
                      icon={section.icon}
                      info={section.info}
                      key={index}
                      title={section.title}
                    />
                  )}

                </List>
              )
            })}
            {/* {sections.map((section, index) => (
             
         
               <List
                color="primary"
                key={index}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >


                {renderNavItems({
                  items: section.children,
                  pathname: location.pathname
                })}


              </List> 
            ))} */}
          </Box>
          {showPoweredBy && <div >
            <Divider />
            <Box sx={{ p: 2, display: "flex", gap: '5px', alignItems: "center" }}>
              <BottomLogo />
              <div>

                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "12px" }}
                >
                  Powered by Procs
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "12px" }}
                >
                  Product of SCAI Tecno
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "11px" }}
                >

                  {/* {version && `ver:${version}`} {!isProd && "- (DEV)"} */}
                  ver. 23.2.0 {ambiente && `- (${ambiente})`}
                </Typography>
              </div>

            </Box>
          </div>}


        </Box >
      </PerfectScrollbar >
      {
        nodi && <OrganizationPopover
          anchorEl={organizationsRef.current}
          onClose={handleCloseOrganizationsPopover}
          open={openOrganizationsPopover}
          nodi={nodi}
          handleChangePermission={handleChangePermission}
          currentNode={currentNode}
          currentPermission={currentPermission}
        />
      }
    </>

  );

  return (
    <>
      {isDesigner ? (
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      ) : (
        <>
          <Hidden lgUp>
            <Drawer
              anchor="left"
              classes={{ paper: classes.mobileDrawer }}
              onClose={onMobileClose}
              open={openMobile}
              variant="temporary"
              PaperProps={{
                style: {
                  background: 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(10px)'
                },
              }}
            >
              {content}
            </Drawer>
          </Hidden>
          <Hidden lgDown>
            <Drawer
              anchor="left"
              classes={{ paper: classes.desktopDrawer }}
              open
              variant="persistent"
            >
              {content}
            </Drawer>
          </Hidden>
        </>
      )}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;