import React from 'react';
import { MenuItem, Popover, Typography, Box } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DomainIcon from '@mui/icons-material/Domain';
import PersonIcon from '@mui/icons-material/PersonOutline';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

const organizations = ['Acme Inc', 'Division Inc'];

export const OrganizationPopover = props => {
  const {
    anchorEl,
    onClose,
    open,
    nodi,
    handleChangePermission,
    currentNode,
    currentPermission,
    ...other
  } = props;

  const handleChange = (nodo, permission) => {
    handleChangePermission(nodo, permission);
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      keepMounted
      onClose={onClose}
      open={open}
      // PaperProps={{ style: { width: 248 } }}
      transitionDuration={0}
      sx={{ zIndex: 10000 }}
      PaperProps={{
        style: {
          background: 'rgba(245, 245, 245, 0.6)',
          backdropFilter: 'blur(10px)'
        },
      }}
      {...other}
    >
      {nodi.map(item => (
        <MenuItem sx={{ display: "flex", gap: '5px' }}
          key={item}
          onClick={() => handleChange(item.nodo, item.permission)}>
          <Box display="flex" sx={{ alignItems: "center", gap: '0px' }}>
            <WorkOutlineIcon fontSize="small" />
            <Typography px="3px">
              {item.nodo}
            </Typography>
          </Box>
          <Box>-</Box>
          <Box display="flex" sx={{ alignItems: "center", gap: '0px' }}>
            <PersonIcon fontSize="small" />
            <Typography px="3px" >
              {item.permission}
            </Typography>
          </Box>
          {currentNode == item.nodo && currentPermission == item.permission && <DoneIcon color='primary' />}
        </MenuItem>
      ))
      }
    </Popover >
  );
};
