import React from 'react';
import { appConfig } from 'src/config';


const BottomLogo = props => {

  const path = `${appConfig.customPath}/static/LogoProcs.png`
  return <img alt="Logo" src={path} height="30px" {...props} referrerPolicy="no-referrer" />;

};

export default BottomLogo;
