import React from 'react';
import { Checkbox, FormControlLabel, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import 'src/index.css';
import { appConfig } from 'src/config';
import { useTranslation } from 'react-i18next';
import SendIcon from '@mui/icons-material/Send';
import Grid from '@mui/material/Grid';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const displayNonChiuderePiu = appConfig.nonChiederePiuSlider;

const tutorialSteps = [
  {
    label: '',
    imgPath: '/static/images/slideshow/01.jpg'
  },
  {
    label: '',
    imgPath: '/static/images/slideshow/02.jpg'
  },
  {
    label: '',
    imgPath: '/static/images/slideshow/03.jpg'
  },
  {
    label: '',
    imgPath: '/static/images/slideshow/04.jpg'
  },
  {
    label: '',
    imgPath: '/static/images/slideshow/05.jpg'
  }
];

const useStyles = makeStyles(theme => ({
  root1: {
    padding: 'env(safe-area-inset-top)  env(safe-area-inset-bottom)',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    // padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 11000
  },
  root: {
    maxWidth: 800,
    flexGrow: 1,
    backgroundColor: 'white'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  img: {
    maxHeight: 800,
    display: 'block',
    maxWidth: 800,
    overflow: 'hidden',
    width: '100%'
  }
}));

const Slider = props => {
  const { closeSlider } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [checked, setChecked] = React.useState(true);
  const maxSteps = tutorialSteps.length;

  const { t } = useTranslation();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <Box className={classes.root1}>
      <div className={classes.root}>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {tutorialSteps.map((step, index) => (
            <div style={{ width: '100%' }} key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <img
                  className={classes.img}
                  src={step.imgPath}
                  alt={step.label}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {t('next')}
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              {t('previous')}
            </Button>
          }
        />
      </div>
      {displayNonChiuderePiu == true && (
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={event => setChecked(event.target.checked)}
              color="primary"
              inputProps={{
                'aria-label': 'primary checkbox'
              }}
            />
          }
          label="Non mostrare più"
        />
      )}
      <Button
        size="large"
        variant="contained"
        style={{ marginBottom: '10px' }}
        color="primary"
        onClick={() =>
          closeSlider(displayNonChiuderePiu == true ? checked : false)
        }
      >
        {t('avanti')}
      </Button>
    </Box>
  );
};

export default Slider;
