import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route, BrowserRouter } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import { appConfig } from 'src/config'
// import SlideShow from 'src/components/SlideShow';

const customPath = appConfig.customPath

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    {/* <SlideShow /> */}
    {/* <BrowserRouter basename={customPath} forceRefresh={true}> */}
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
    {/* </BrowserRouter> */}
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: `${customPath}/404`,
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: `${customPath}/maintenance`,
    component: lazy(() => import('src/views/errors/MaintenanceView'))
  },
  {
    exact: true,
    path: `${customPath}/error-procs`,
    component: lazy(() => import('src/views/errors/GenericError'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: [`${customPath}/login`, `${customPath}/`],
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: `${customPath}/login-unprotected`,
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: `${customPath}/spid`,
    component: lazy(() => import('src/views/SpidRedirect'))
  },
  {
    exact: true,
    path: `${customPath}/loginSSO`,
    component: lazy(() => import('src/views/SSORedirect'))
  },
  {
    exact: true,
    path: `${customPath}/initQuestionario`,
    component: lazy(() => import('src/views/QuestionarioRedirect'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: `${customPath}/register`,
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: `${customPath}/privacy`,
    component: lazy(() => import('src/views/auth/RegisterView/privacy.js'))
  },
  {
    exact: true,
    path: `${customPath}/register-unprotected`,
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: `${customPath}/confirm-user`,
    component: lazy(() => import('src/views/auth/RegisterView/ConfirmUserPage'))
  },
  {
    exact: true,
    path: `${customPath}/password-recovery`,
    component: lazy(() =>
      import('src/views/auth/RegisterView/passwordRecovery')
    )
  },
  {
    exact: true,
    path: `${customPath}/forgot-password`,
    component: lazy(() => import('src/views/auth/RegisterView/forgotPassword'))
  },
  {
    path: `${customPath}/app`,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: false,
        path: `${customPath}/app/account/:procURL?/:stepURL?`,
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: `${customPath}/app/calendar`,
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: [`${customPath}/app/chat/new`, `${customPath}/app/chat/:threadKey`],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: `${customPath}/app/chat`,
        component: () => <Redirect to={`${customPath}/app/chat/new`} />
      },
      {
        exact: true,
        path: `${customPath}/app/extra/charts/apex`,
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: `${customPath}/app/extra/forms/formik`,
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: `${customPath}/app/extra/forms/redux`,
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: `${customPath}/app/extra/editors/draft-js`,
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: `${customPath}/app/extra/editors/quill`,
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        exact: true,
        path: `${customPath}/app/kanban`,
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: [
          `${customPath}/app/mail/label/:customLabel/:mailId?`,
          `${customPath}/app/mail/:systemLabel/:mailId?`
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: `${customPath}/app/mail`,
        component: () => <Redirect to={`${customPath}/app/mail/all`} />
      },
      {
        exact: true,
        path: `${customPath}/app/management/customers`,
        component: lazy(() => import('src/views/customer/CustomerListView'))
      },
      {
        exact: false,
        path: `${customPath}/app/procesdetail/:procURL/:stepURL/:notifyURL?/:groupURL?`,
        component: lazy(() => import('src/views/detail/ProcesDetail'))
        // component: () => <Redirect to=" " />
      },

      /* {
        exact: false,
        path: `${customPath}/app/procesdesign`,
        component: lazy(() => import('src/views/designer'))
      }, */
      {
        exact: false,
        path: `${customPath}/app/translations`,
        component: lazy(() => import('src/views/designer/Translations'))
      },
      {
        exact: false,
        path: `${customPath}/app/users-list`,
        component: lazy(() => import('src/views/users-list'))
      },
      {
        exact: false,
        path: `${customPath}/app/edit-user/:id`,
        component: lazy(() => import('src/views/users-list/edit-user'))
      },
      {
        exact: true,
        path: `${customPath}/app/management/customers/:customerId`,
        component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: `${customPath}/app/management/customers/:customerId/edit`,
        component: lazy(() => import('src/views/customer/CustomerEditView'))
      },
      {
        exact: true,
        path: `${customPath}/app/management/invoices`,
        component: lazy(() => import('src/views/invoice/InvoiceListView'))
      },
      {
        exact: true,
        path: `${customPath}/app/management/invoices/:invoiceId`,
        component: lazy(() => import('src/views/invoice/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: `${customPath}/app/management/orders`,
        component: lazy(() => import('src/views/order/OrderListView'))
      },
      {
        exact: true,
        path: `${customPath}/app/management/orders/:orderId`,
        component: lazy(() => import('src/views/order/OrderDetailsView'))
      },
      {
        exact: true,
        path: `${customPath}/app/management/products`,
        component: lazy(() => import('src/views/product/ProductListView'))
      },
      {
        exact: true,
        path: `${customPath}/app/management/products/create`,
        component: lazy(() => import('src/views/product/ProductCreateView'))
      },
      {
        exact: true,
        path: `${customPath}/app/management`,
        component: () => <Redirect to={`${customPath}/app/management/customers`} />
      },
      {
        exact: true,
        path: `${customPath}/app/projects/overview`,
        component: lazy(() => import('src/views/project/OverviewView'))
      },
      {
        exact: true,
        path: `${customPath}/app/projects/browse`,
        component: lazy(() => import('src/views/project/ProjectBrowseView'))
      },
      {
        exact: true,
        path: `${customPath}/app/projects/create`,
        component: lazy(() => import('src/views/project/ProjectCreateView'))
      },
      {
        exact: true,
        path: `${customPath}/app/projects/:id`,
        component: lazy(() => import('src/views/project/ProjectDetailsView'))
      },
      {
        exact: true,
        path: `${customPath}/app/projects`,
        component: () => <Redirect to={`${customPath}/app/projects/browse`} />
      },
      {
        exact: true,
        path: `${customPath}/app/reports/dashboard`,
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: false,
        path: `${customPath}/app/reports/dashboardProcs/:procURL?/:stepURL?`,
        component: lazy(() =>
          import('src/views/reports/DashboardProcs/DashboardProcsView/index')
        )
      },
      {
        exact: true,
        path: `${customPath}/app/reports/dashboard-alternative`,
        component: lazy(() =>
          import('src/views/reports/DashboardAlternativeView')
        )
      },
      {
        exact: true,
        path: `${customPath}/app/reports`,
        component: () => <Redirect to={`${customPath}/app/reports/dashboard`} />
      },
      {
        exact: true,
        path: `${customPath}/app/social/feed`,
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: `${customPath}/app/social/profile`,
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: `${customPath}/app/social`,
        component: () => <Redirect to={`${customPath}/app/social/profile`} />
      },
      {
        exact: true,
        path: `${customPath}/app`,
        component: () => <Redirect to={`${customPath}/app/reports/dashboard`} />
      },
      {
        component: () => <Redirect to={`${customPath}/404`} />
      }
    ]
  },
  {
    path: `${customPath}/docs`,
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: `${customPath}/docs`,
        component: () => <Redirect to={`${customPath}/docs/welcome`} />
      },
      {
        exact: true,
        path: `${customPath}/docs/welcome`,
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/getting-started`,
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/environment-variables`,
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/deployment`,
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/api-calls`,
        component: lazy(() => import('src/views/docs/APICallsView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/analytics`,
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/authentication`,
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/routing`,
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/settings`,
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/state-management`,
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/theming`,
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/support`,
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/changelog`,
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/designer/overview`,
        component: lazy(() => import('src/views/docs/DesignerDocsView/OverviewView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/designer/processes`,
        component: lazy(() => import('src/views/docs/DesignerDocsView/ProcessesView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/designer/messages`,
        component: lazy(() => import('src/views/docs/DesignerDocsView/MessagesView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/designer/system-vars`,
        component: lazy(() => import('src/views/docs/DesignerDocsView/SystemVarsView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/designer/menu`,
        component: lazy(() => import('src/views/docs/DesignerDocsView/MenuView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/designer/nodes`,
        component: lazy(() => import('src/views/docs/DesignerDocsView/NodesView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/designer/organizations`,
        component: lazy(() => import('src/views/docs/DesignerDocsView/OrganizationsView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/designer/settings`,
        component: lazy(() => import('src/views/docs/DesignerDocsView/SettingsView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/designer/export`,
        component: lazy(() => import('src/views/docs/DesignerDocsView/ExportView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/designer/import`,
        component: lazy(() => import('src/views/docs/DesignerDocsView/ImportView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/designer/translations`,
        component: lazy(() => import('src/views/docs/DesignerDocsView/TranslationsView'))
      },
      {
        exact: true,
        path: `${customPath}/docs/designer/plugins`,
        component: lazy(() => import('src/views/docs/DesignerDocsView/PluginsView'))
      },
      {
        component: () => <Redirect to={`${customPath}/404`} />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        component: () => <Redirect to={`${customPath}/404`} />
      }
    ]
  }
];

export default routes;
