import React from "react";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  designerTitle: "",
};
const slice = createSlice({
  name: "designerTitle",
  initialState,
  reducers: {
    getDesignerTitle(state, action) {
      const { designerTitle } = action.payload;
      state.designerTitle = designerTitle;
    },
  },
});

export const reducer = slice.reducer;

export const getDesignerTitle = (Component) => (dispatch) => {
  let data = { designerTitle: Component };
  dispatch(slice.actions.getDesignerTitle(data));
};

export default slice;
