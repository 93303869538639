import React from 'react'
import { makeStyles, styled } from '@mui/styles';
import Badge from '@mui/material/Badge';

const StyledBadgeNotifications = (props) => {

    const { color, children } = props
    const StyledBadge = styled(Badge)(({ theme }) => ({
        // '@global': {
        //   '@keyframes ripple': {
        //     '0%': {
        //       transform: 'scale(.8)',
        //       opacity: 1,
        //     },
        //     '100%': {
        //       transform: 'scale(2.4)',
        //       opacity: 0,
        //     },
        //   },
        // },

        '& .MuiBadge-badge': {
            backgroundColor: color,
            color: color,
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },

    }));
    return (
        <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
        >
            {children}
        </StyledBadge>
    )
}

export default StyledBadgeNotifications
