import axios from 'axios';
import { setCookie } from '../restBox/restBox';
import { nodePrefix } from './common';
import { checkError } from './common';
import { errorHandler } from './common';
import { appConfig } from 'src/config';

const http = axios.create({
  baseURL: nodePrefix
});

const baseurlRest = appConfig.restPrefix;
const versionNumber = appConfig.versionNumber;

const profilo_specifico = appConfig.profilo;
const instance = axios.create({
  baseURL: baseurlRest,
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
    'Access-Control-Expose-Headers': baseurlRest,
    versionNumber
  }
});

export const register = async (email, name, password, lingua, token) => {
  return instance
    .post('/LOGIN/NEWUSER', null, {
      params: {
        email: email,
        firstName: name,
        password: password,
        language: lingua,
        token: token,
        permission: profilo_specifico
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => errorHandler(error));
};

export const confirm = async (email, token) => {
  return instance
    .post('/LOGIN/CONFIRMUSER', null, {
      params: {
        email: email,
        token: token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => errorHandler(error));
};

export async function login(id, password, Gjwt) {
  return http
    .post('/login', { id, password, Gjwt })
    .then(response => {
      const resp = response.data;
      let jwt = resp.jwt;
      let name = resp.user;
      let ruoli = JSON.stringify(resp.response.functions);
      let color = resp.response.primaryColor;
      let permission = resp.response.permission;
      let companyCode = resp.response.companyCode;
      let companyDescription = resp.response.companyDescription;
      let mail = resp.response.mail;
      let menu = JSON.stringify(resp.response.menu.root);
      setCookie('jwt', jwt, 1);
      setCookie('name', name, 1);
      setCookie('ruoli', ruoli, 1);
      setCookie('color', color || '#152E4C', 1);
      setCookie('permission', permission, 1);
      setCookie('companyCode', companyCode, 1);
      setCookie('companyDescription', companyDescription, 1);
      setCookie('mail', mail, 1);
      setCookie('menu', menu, 1);
      return resp;
    })
    .catch(error => errorHandler(error));
}

export const loginWithToken = token => {
  return http
    .post('/LOGIN/DECODETOKEN', { token })
    .then(response => {
      const resp = response.data;
      let name = resp.user;
      let jwt = resp.jwt;
      let ruoli = JSON.stringify(resp.response.functions);
      let color = resp.response.primaryColor;
      let companyDescription = resp.response.companyDescription;
      let mail = resp.response.mail;
      setCookie('jwt', jwt, 1);
      setCookie('name', name, 1);
      setCookie('ruoli', ruoli, 1);
      setCookie('color', color || '#152E4C', 1);
      setCookie('companyDescription', companyDescription, 1);
      setCookie('mail', mail, 1);
      setCookie('fromPortal', true);
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const registerUser = (firstName, email, psw, lingua, token) => {
  return http
    .post('/LOGIN/NEWUSER', { firstName, email, psw, lingua, token })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const confirmUser = (email, token) => {
  return http
    .post('/LOGIN/CONFIRMUSER', { email, token })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const lostPsw = email => {
  return instance
    .post('/LOGIN/NOPASSWORD', null, {
      params: { email },
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const newPsw = (email, token, psw) => {
  return instance
    .post('/LOGIN/NEWPASSWORD', null, {
      params: { email, token, newpassword: psw },
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const changePsw = (email, oldPsw, psw) => {
  return instance
    .post('/LOGIN/CHANGEPASSWORD', null, {
      params: { email: email, oldpassword: oldPsw, newpassword: psw },
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const usersList = () => {
  return instance
    .get('/LOGIN/GET/USERS', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getUserById = id => {
  return instance
    .get(`/LOGIN/GET/USER/${id}`, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const newUser = (email, name, company, permission, password) => {
  return instance
    .post('/LOGIN/NEW/USER', null, {
      params: {
        email: email,
        name: name,
        company: company,
        permission: permission,
        password: password
      },
      headers: {
        'content-type': 'application/json',
        jwt: localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getPermissions = () => {
  return instance
    .get(`/LOGIN/GET/PERMISSIONS`, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const updateUser = (id, email, name, company, permission, password) => {
  return instance
    .post(
      '/LOGIN/UPDATEUSER/' + id,
      { email, name, company, permission, password },
      {
        headers: {
          'content-type': 'application/json',
          jwt: localStorage.getItem('accessToken'),
          versionNumber
        }
      }
    )
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const updateNodePermission = (node, permission) => {
  return instance
    .post(`/auth/permissions/${node.replaceAll('/', '§')}/${permission}/`, null, {
      headers: {
        'content-type': 'application/json',
        jwt: localStorage.getItem('accessToken')
      }
    })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};