import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { appConfig } from 'src/config';
import { getMessaging, onMessage } from 'firebase/messaging';
import { notificationsHeader } from 'src/api/notifications-api';
import { Box, Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';

const initialState = {
  notifications: []
};
const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications(state, action) {
      const { notifications } = action.payload;
      state.notifications = notifications;
    },
    resetNotifications(state) {
      state.notifications = [];
    }
  }
});

export const reducer = slice.reducer;
const Msg = ({ titolo, body }) => {
  return (
    <Box display="flex" flexDirection="column">
      <strong>{titolo}</strong>
      <Typography>{body}</Typography>
    </Box>
  );
};



export const getNotifications = (filtro, stato, processo) => async dispatch => {
  if (appConfig.enablePushNotifications) {
    const messaging = getMessaging();
    onMessage(messaging, async payload => {
      console.log('Message received. ', payload);
      toast(<Msg titolo={payload.data.title} body={payload.data.body} />);
      const responsePush = await notificationsHeader(filtro, stato, processo);
      if (responsePush.server == 200) {
        let dataPush = { notifications: responsePush.response };
        dispatch(slice.actions.getNotifications(dataPush));
      }
    });
  }

  dispatch(slice.actions.resetNotifications());
  const response = await notificationsHeader(filtro, stato, processo);
  if (response.server == 200) {
    let data = { notifications: response.response };
    dispatch(slice.actions.getNotifications(data));
  }
};

export default slice;
