import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { avaibleLanguages } from 'src/api/designer-api';
const languageOptions = {
  en: {
    icon: '/static/icons/uk_flag.svg',
    label: 'inglese',
    key: "en"
  },
  it: {
    icon: '/static/icons/it_flag.svg',
    label: 'italiano',
    key: "it"
  },
  fr: {
    icon: '/static/icons/fr_flag.svg',
    label: 'francese',
    key: "fr"
  },
  ar: {
    icon: '/static/icons/ar_flag.svg',
    label: 'arabo',
    key: "ar"
  },
  pu: {
    icon: '/static/icons/pu_flag.svg',
    label: 'punjabi',
    key: "pu"
  },
  de: {
    icon: '/static/icons/de_flag.svg',
    label: 'German',
    key: "de"
  },
  es: {
    icon: '/static/icons/es_flag.svg',
    label: 'Spanish',
    key: "es"
  }

};
const initialState = {
  languages: [],
  mappedLanguages: []
};
const slice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    getLaguages(state, action) {
      const { languages, mappedLanguages } = action.payload;
      state.languages = languages;
      state.mappedLanguages = mappedLanguages;
    }
  }
});

export const reducer = slice.reducer;


export const getLaguages = () => async dispatch => {
  const response = await avaibleLanguages();
  if (response.server == 200) {
    let mappedLanguages = []
    response.response.map((language) => {
      mappedLanguages.push(languageOptions[language])
    })
    let data = { languages: response.response, mappedLanguages: mappedLanguages };
    dispatch(slice.actions.getLaguages(data));
  }
};

export default slice;
