import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { getAllPlugins } from 'src/api/plugins-api';

const initialState = {
  plugins: []
};
const slice = createSlice({
  name: 'plugins',
  initialState,
  reducers: {
    getPlugins(state, action) {
      const { plugins } = action.payload;
      state.plugins = plugins;
    }
  }
});

export const reducer = slice.reducer;


export const getPlugins = () => async dispatch => {

  const response = await getAllPlugins();

  if (response != "Generic error") {
    let data = { plugins: response };
    dispatch(slice.actions.getPlugins(data));
  }

};

export default slice;
