import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { appConfig } from 'src/config';

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const customPath = appConfig.customPath;

  if (!isAuthenticated) {
    return <Redirect to={`${customPath}/login`} />;
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
