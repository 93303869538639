import _ from 'lodash';
import { colors } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';
import toast from 'react-hot-toast';

const baseOptions = {
  direction: 'ltr',
  shape: {
    borderRadius: 8
  },
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '9px 24px'
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontWeight: 500
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          my: 0
        }
      }
    }
  },
};

const themesOptions = [
  {
    name: THEMES.DLL,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#666666'
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#0099FF'
      },
      primary: {
        light: '#33ADFF',
        main: '#0099FF',
        dark: '#006BB2',
        contrastText: '#FFFFFF',
      },
      secondary: {
        light: '#5B5B5B',
        main: '#333333',
        dark: '#232323',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#0099FF',
        secondary: '#666666'
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.ALFAMATION,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#005C5A'
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: '#18727B'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#18727B'
      },
      primary: {
        light: '#468E95',
        main: '#18727B',
        dark: '#104F56',
        contrastText: '#FFFFFF',
      },
      secondary: {
        light: '#468E95',
        main: '#18727B',
        dark: '#104F56',
        contrastText: '#FFFFFF',
      },
      text: {
        primary: '#18727B',
        secondary: '#202124'
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.MORGANTI,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#005C5A'
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: '#005C5A'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#4E6C64'
      },
      primary: {
        light: '#718983',
        main: '#4E6C64',
        dark: '#364B46',
        contrastText: '#FFFFFF'
      },
      secondary: {
        light: '#337C7B',
        main: '#005C5A',
        dark: '#00403E',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#005C5A',
        secondary: '#202124'
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.DEMO1,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#004ab3'
      },
      primary: {
        light: '#4F9BD1',
        main: '#2382c6',
        dark: '#185B8A',
        contrastText: '#FFF'
      },
      secondary: {
        light: '#947F47',
        main: '#7a5f19',
        dark: '#554211',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#264D7A',
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.HUB,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#393B40'
      },
      primary: {
        light: '#606266',
        main: '#393B40',
        dark: '#27292C',
        contrastText: '#ffffff'
      },
      secondary: {
        light: '#F6D864',
        main: '#F4CF3E',
        dark: '#AA902B',
        contrastText: '#000000'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.CGT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#393B40'
      },
      primary: {
        main: '#393B40',
        dark: '#27292C',
      },
      secondary: {
        main: '#F4CF3E',
        dark: '#27292C',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.MPS,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#881813'
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: '#514545'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#881813'
      },
      primary: {
        light: '#9F4642',
        main: '#881813',
        dark: '#5F100D',
        contrastText: '#FFFFFF'
      },
      secondary: {
        light: '#9F4642',
        main: '#881813',
        dark: '#5F100D',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#881813',
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.PUNTACCAPO,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#007829'
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: '#7cac25'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#007829'
      },
      primary: {
        light: '#339353',
        main: '#007829',
        dark: '#00541c',
        contrastText: '#fff'
      },
      secondary: {
        light: '#96BC50',
        main: '#7cac25',
        dark: '#567819',
        contrastText: '#fff'
      },
      text: {
        primary: '#007829',
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.SICILIA,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#881813'
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: '#514545'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#881813'
      },
      primary: {
        light: '#9F4642',
        main: '#881813',
        dark: '#5F100D',
        contrastText: '#FFFFFF'
      },
      secondary: {
        light: '#9F4642',
        main: '#881813',
        dark: '#5F100D',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#881813',
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.BCC,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#264D7A'
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: '#514545'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#264D7A'
      },
      primary: {
        light: '#517094',
        main: '#264D7A',
        dark: '#1A3555',
        contrastText: '#FFFFFF'
      },
      secondary: {
        light: '#3B9968',
        main: '#0B8043',
        dark: '#07592E',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#264D7A',
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  }
  ,
  {
    name: THEMES.ONBOARDING,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#264D7A'
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: '#514545'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#264D7A'
      },
      primary: {
        light: '#517094',
        main: '#264D7A',
        dark: '#1A3555',
        contrastText: '#FFFFFF'
      },
      secondary: {
        light: '#3B9968',
        main: '#0B8043',
        dark: '#07592E',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#264D7A',
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.NEXI,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#292eac'
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: '#514545'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#292eac'
      },
      primary: {
        light: '#517094',
        main: '#000000',
        dark: '#1A3555',
        contrastText: '#FFFFFF'
      },
      secondary: {
        light: '#000000',
        main: '#000000',
        dark: '#000000',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#000000',
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.ZANIBELLI,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#545DA3'
      },
      primary: {
        light: '#767DB5',
        main: '#545DA3',
        dark: '#3A4172',
        contrastText: '#000000'
      },
      secondary: {
        light: '#7973EF',
        main: '#5850EC',
        dark: '#3D38A5',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.INDUSTRY,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: colors.lightBlue[700]
      },
      primary: {
        light: '#349FDA',
        main: colors.lightBlue[700],
        dark: '#015F92',
        contrastText: '#FFFFFF'
      },
      secondary: {
        light: '#7973EF',
        main: '#5850EC',
        dark: '#3D38A5',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.PA,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: colors.green[700]
      },
      primary: {
        light: '#5FA463',
        main: colors.green[700],
        dark: '#27632A',
        contrastText: '#FFFFFF'
      },
      secondary: {
        light: '#7973EF',
        main: '#5850EC',
        dark: '#3D38A5',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: colors.indigo[600]
      },
      primary: {
        light: '#606DBB',
        main: colors.indigo[600],
        dark: '#273377',
        contrastText: '#000000'
      },
      secondary: {
        light: '#7973EF',
        main: '#5850EC',
        dark: '#3D38A5',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34',
        main: '#8a85ff'
      },
      primary: {
        light: '#A19DFF',
        main: '#8a85ff',
        dark: '#605DB2',
        contrastText: '#000000'
      },
      secondary: {
        light: '#A19DFF',
        main: '#8a85ff',
        dark: '#605DB2',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: strongShadows
  },

  {
    name: THEMES.UNICORN,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d',
        main: '#a67dff'
      },
      primary: {
        light: '#B797FF',
        main: '#a67dff',
        dark: '#7457B2',
        contrastText: '#000000'
      },
      secondary: {
        light: '#B797FF',
        main: '#a67dff',
        dark: '#7457B2',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.GG2,
    // components: {
    //   MuiButton: {
    //     styleOverrides: {
    //       // Name of the slot
    //       contained: {
    //         // Some CSS
    //         color: '#000000',
    //         backgroundColor: '#FFFFFF',
    //         // boxShadow:
    //         //   '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    //         border: ' black solid 1px',
    //         '&:hover': {
    //           backgroundColor: '#E0E0E0'
    //         }
    //       }
    //     }
    //   }
    // },
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#FFFFFF'
      },
      primary: {
        light: '#333333',
        main: '#000000',
        dark: '#000000',
        contrastText: '#fff'
      },
      secondary: {
        light: '#947F47',
        main: '#7a5f19',
        dark: '#554211',
        contrastText: '#000000'
      },
      text: {
        primary: '#264D7A',
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.black
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.SIA,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#BE0730'
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#FFFFFF'
      },
      primary: {
        light: '#FFFFFF',
        main: '#FFFFFF',
        dark: '#B2B2B2',
        contrastText: '#000000'
      },
      secondary: {
        light: '#CB3859',
        main: '#BE0730',
        dark: '#850421',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#BE0730',
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: '#000000'
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.TECNO,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#264D7A'
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: '#514545'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#264D7A'
      },
      primary: {
        light: '#517094',
        main: '#264D7A',
        dark: '#1A3555',
        contrastText: '#FFFFFF'
      },
      secondary: {
        light: '#3B9968',
        main: '#0B8043',
        dark: '#07592E',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#264D7A',
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.CONSULTING,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#142D4C'
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: '#514545'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        main: '#EA7300'
      },
      primary: {
        light: '#517094',
        main: '#142D4C',
        dark: '#1A3555',
        contrastText: '#FFFFFF'
      },
      secondary: {
        light: '#3B9968',
        main: '#0B8043',
        dark: '#07592E',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#142D4C',
        secondary: colors.blueGrey[600]
      },
      appBarText: {
        main: colors.common.white
      }
    },
    shadows: softShadows
  },
];

export const createThemeExport = (config = {}) => {
  let themeOptions = themesOptions.find(theme => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    // toast.error(`The theme ${config.theme} is not valid. Switched to DEMO1`);
    themeOptions = themesOptions.find(theme => theme.name === "DEMO1");;
  }

  let theme = createTheme(baseOptions, themeOptions, {
    direction: config.direction
  });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
