import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import { useSelector } from 'src/store';
import useSettings from 'src/hooks/useSettings';
import moment from 'moment';
import { updateLang } from 'src/api/functions-api';
import { useHistory } from 'react-router';
import useAuth from 'src/hooks/useAuth';



export const LanguagePopover = (props) => {
    const { anchorEl, onClose, open, ...other } = props;
    const { i18n, t } = useTranslation();
    const { mappedLanguages } = useSelector(state => state.languages);
    const { settings, saveSettings } = useSettings();
    const history = useHistory();
    const { user } = useAuth();

    const handleChange = async (language) => {
        onClose?.();
        i18n.changeLanguage(language);
        saveSettings({
            ...settings,
            lang: language,
            direction: language == 'ar' ? 'rtl' : 'ltr'
        });
        moment.locale(language);
        document.body.dir = language == 'ar' ? 'rtl' : 'ltr';
        if (user) {
            const resp = await updateLang(user.id, language);
            if (resp.server == 200) {
                history.go(0);
            }
        }
    };

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom'
            }}
            PaperProps={{
                style: {
                    background: 'rgba(245, 245, 245, 0.6)',
                    backdropFilter: 'blur(10px)'
                },
                sx: { width: 240 }
            }}
            keepMounted
            onClose={onClose}
            open={open}

            transitionDuration={0}
            {...other}>
            {mappedLanguages.map((language) => (
                <MenuItem
                    onClick={() => handleChange(language.key)}
                    key={language.key}
                >
                    <ListItemIcon>
                        <Box
                            sx={{
                                display: 'flex',
                                height: 20,
                                width: 20,
                                '& img': {
                                    width: '100%'
                                }
                            }}
                        >
                            <img
                                alt={language.label}
                                src={language.icon}
                            />
                        </Box>
                    </ListItemIcon>
                    <ListItemText
                        primary={(
                            <Typography variant="subtitle2">
                                {t(language.label)}
                            </Typography>
                        )}
                    />
                </MenuItem>
            ))}
        </Popover>
    );
};


