import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as needUpdateReducer } from 'src/slices/needUpdate';
import { reducer as availableLanguagesReducer } from 'src/slices/availableLanguages';
import { reducer as pluginsReducerReducer } from 'src/slices/plugins';
import { reducer as commandListReducer } from 'src/slices/commandList';
import { reducer as designerTitleReducer } from "../slices/designerTitle";

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  needUpdate: needUpdateReducer,
  languages: availableLanguagesReducer,
  plugins: pluginsReducerReducer,
  commandsList: commandListReducer,
  designerTitle: designerTitleReducer,
});

export default rootReducer;
