import React from 'react';
import useAuth from 'src/hooks/useAuth';
import { appConfig } from 'src/config';

const customUrl = appConfig.customPath;

const Logo = props => {
  const { user } = useAuth();
  if (!user) return null

  const path =
    user.logo ? `${customUrl}/static/${user.logo}` : `${customUrl}${appConfig.logoPath}`
  // `/static/LogoRegioneMarche2.png`
  return <img alt="Logo" src={path} style={{ maxHeight: "80px" }} {...props} referrerPolicy="no-referrer" />;

};

export default Logo;
