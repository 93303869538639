import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Collapse, ListItem, Icon as MuiIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useHistory, useLocation } from 'react-router';
import { names } from "src/utils/iconNames"
import * as Icons from 'react-feather';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  iconMui: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: "-2px",
    marginRight: "6px",
    color: theme.palette.text.secondary
  },
  title: {
    color: theme.palette.text.secondary,
    marginRight: 'auto'
  },
  active: {
    '& $title': {
      fontWeight: "900 !important",
      color: theme.palette.secondary.main
    },
    '& $icon': {
      color: theme.palette.secondary.main
    },
    '& $iconMui': {
      color: theme.palette.secondary.main
    }
  }
}));

const NavItem = (props) => {
  const classes = useStyles();
  const {
    children,
    className,
    depth,
    href,
    icon,
    info: Info,
    open: openProp,
    title,
    page,
    proc,
    step,
    pars,
    ...rest
  } = props
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let history = useHistory();

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 10 + 8 * depth;
  }

  const createIcon = () => {
    if (names.includes(icon)) {
      return <MuiIcon className={classes.iconMui} size="20" >{icon}</MuiIcon>
    } else {
      const IconComponent = Icons[icon]
      if (IconComponent) {

        return <IconComponent className={classes.icon} size="20" />
      } else return null
    }

  }

  const style = { paddingLeft };
  if (children) {
    return (
      <>
        <ListItem
          className={clsx(classes.item, className)}
          disableGutters
          key={title}
          {...rest}
        >
          <Button className={classes.button} onClick={handleToggle} style={style}>
            {icon && createIcon()}
            <span className={classes.title}>{title}</span>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
          <Collapse in={open}>{children}</Collapse>
        </ListItem>
      </>
    );
  }

  return (
    <>
      <ListItem
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          exact
          style={style}
          component={RouterLink}
          to={href}
        >
          {icon && createIcon()}
          <span className={classes.title}>{title}</span>
          {/* {Info && <Info />} */}
        </Button>
      </ListItem>
    </>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
