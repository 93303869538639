import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import NavBar from './NavBar';
import TopBar from './TopBar';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { topBarConfig } from "src/config"

import { useLocation } from 'react-router-dom';
import '../style.css';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
    // padding: "env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)",
    // backgroundColor: "blueviolet",
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: topBarConfig.hideTopbar ? 0 : 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  wrapperDesigner: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: topBarConfig.hideTopbar ? 0 : 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ children }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isDesigner, setisDesigner] = useState(false);
  const location = useLocation();

  const classes = useStyles();

  useEffect(() => {
    if (location.pathname.includes('ProcesDesign')) {
      setisDesigner(true);
    } else {
      setisDesigner(false);
    }
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      {!topBarConfig.hideTopbar &&
        <TopBar
          isDesigner={isDesigner}
          onMobileNavOpen={() => setMobileNavOpen(true)}
        />
      }
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div
        className={clsx({
          [classes.wrapper]: !isDesigner,
          [classes.wrapperDesigner]: isDesigner
        })}
      >
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
