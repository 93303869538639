import yn from 'yn';
export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: 'AIzaSyCZKPFvdyhT90zms3-qgJwrh7mW2kDYQJg', //process.env.REACT_APP_FIREBASE_API_KEY,
  appId: '1:662358465117:web:9825805538362d4210cbb4', //process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: 'procs-b0af5.firebaseapp.com', //process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: '',
  messagingSenderId: '662358465117', //process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: 'procs-b0af5', //process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: 'procs-b0af5.appspot.com' //process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const vapidkey = process.env.REACT_APP_VAPID_KEY;

export const loginConfig = {
  clientId: process.env.REACT_APP_GOOGLE_OAUTH2_KEY,
  type: process.env.REACT_APP_LOGIN_TYPE || "credentials"
};

const getValueView = (name, defaultValue) => {
  let finalValue = defaultValue
  if (window.globalConfig) {
    if (window.globalConfig.viewConfig[name] !== undefined) {
      finalValue = window.globalConfig.viewConfig[name]
    }
  }
  return finalValue
}

const getValueApp = (name, defaultValue) => {
  let finalValue = defaultValue
  if (window.globalConfig) {
    if (window.globalConfig.appConfig[name] !== undefined) {
      finalValue = window.globalConfig.appConfig[name]
    }
  }
  return finalValue
}

const getValueTopBar = (name, defaultValue) => {
  let finalValue = defaultValue
  if (window.globalConfig) {
    if (window.globalConfig.topBarConfig[name] !== undefined) {
      finalValue = window.globalConfig.topBarConfig[name]
    }
  }
  return finalValue
}

export const viewConfig = {
  account: getValueView("account", yn(process.env.REACT_APP_ACCOUNT_VISIBLE || true)),
  theme: getValueView("theme", yn(process.env.REACT_APP_SETTINGS_THEME_VISIBLE || false)),
  kanban: getValueView("kanban", yn(process.env.REACT_APP_KANBAN_VISIBLE || false)),
  notifications: getValueView("notifications", yn(process.env.REACT_APP_SAMPLE_NOTIFCATION_VISIBLE || false)),
  dashboardButtons: getValueView("dashboardButtons", yn(process.env.REACT_APP_DASHBOARD_BUTTONS_VISIBLE || false))
};

export const appConfig = {
  restPrefix: getValueApp("restPrefix", process.env.REACT_APP_REST_PREFIX || 'http://localhost:8094/'),
  customPath: getValueApp("customPath", process.env.REACT_APP_CUSTOM_PATH || ""),
  appName: getValueApp("appName", process.env.REACT_APP_APPLICATION_NAME || 'Procs'),
  logoPath: getValueApp("logoPath", process.env.REACT_APP_LOGO_PATH || '/static/LogoProcs.png'),
  displayCookie: getValueApp("displayCookie", yn(process.env.REACT_APP_DISPLAY_COOKIES || false)),

  availableLanguages: getValueApp("availableLanguages", process.env.REACT_APP_AVAILABLE_LANGUAGES || 'it'),
  profilo: getValueApp("profilo", process.env.REACT_APP_PROFILO || 'UTENTE'),
  displayPopup: getValueApp("displayPopup", yn(process.env.REACT_APP_PRIVACY_POPUP || true)),
  displaySlide: getValueApp("displaySlide", yn(process.env.REACT_APP_DISPLAY_SLIDE || false)),
  nonChiederePiuSlider: getValueApp("nonChiederePiuSlider", yn(process.env.REACT_APP_NON_CHIEDERE_PIU_SLIDER || false)),

  idFrontEnd: getValueApp("idFrontEnd", process.env.REACT_APP_IDFRONTEND || 'WEB'),
  defaultTheme: getValueApp("defaultTheme", process.env.REACT_APP_THEME_DEFAULT || 'DEMO1'),
  linkLogo: getValueApp("linkLogo", process.env.REACT_APP_LINK_LOGO || ''),
  logoPosition: getValueApp("logoPosition", process.env.REACT_APP_LOGO_POSITION || 'left'),
  displayRegistration: getValueApp("displayRegistration", yn(process.env.REACT_APP_REGISTRATION_FORM || false)),
  notificationsVisible: getValueApp("notificationsVisible", yn(process.env.REACT_APP_ACCOUNT_NOTIFICATIONS_VISIBLE || false)),

  emailEnabled: getValueApp("emailEnabled", yn(process.env.REACT_APP_ACCOUNT_EMAIL_ENABLED || true)),
  linkToLogin: getValueApp("linkToLogin", yn(process.env.REACT_APP_LINK_TO_LOGIN || true)),
  displayPrivacyAgree: getValueApp("displayPrivacyAgree", yn(process.env.REACT_APP_PRIVACY_AGREE || false)),
  privacyLink: getValueApp("privacyLink", process.env.REACT_APP_PRIVACY_link || '#'),
  searchInDashboardVisible: getValueApp("searchInDashboardVisible", yn(process.env.REACT_APP_SEARCH_VISIBLE || true)),
  timeLinePast: getValueApp("timeLinePast", process.env.REACT_APP_COLOR_TM_PAST || 'green'),
  timeLinePresent: getValueApp("timeLinePresent", process.env.REACT_APP_COLOR_TM_PRESENT || '#ebba34'),
  timeLineFuture: getValueApp("timeLineFuture", process.env.REACT_APP_COLOR_TM_FUTURE || 'grey'),
  timeLineError: getValueApp("timeLineError", process.env.REACT_APP_COLOR_TM_ERROR || 'red'),
  showindexIconDashboard: getValueApp("showindexIconDashboard", yn(process.env.REACT_APP_SHOW_INDEX_DB || true)),
  showReadingModeBold: getValueApp("showReadingModeBold", yn(process.env.REACT_APP_SHOW_RM_BOLD || true)),
  procModificaProfilo: getValueApp("procModificaProfilo", process.env.REACT_APP_PROC_PROFILO || ''),
  stepModificaProfilo: getValueApp("stepModificaProfilo", process.env.REACT_APP_STEP_PROFILO || ''),
  enablePushNotifications: getValueApp("enablePushNotifications", yn(process.env.REACT_APP_ENABLE_PUSH_NOTIFICATIONS || false)),
  showNoNotifications: getValueApp("showNoNotifications", yn(process.env.REACT_APP_SHOW_NO_NOTIFICATIONS || false)),
  versionNumber: getValueApp("versionNumber", process.env.REACT_APP_VERSION_NUMBER || ""),
  linkIOSapp: getValueApp("linkIOSapp", process.env.REACT_APP_LINK_IOS_APP || "#"),
  linkANDROIDapp: getValueApp("linkANDROIDapp", process.env.REACT_APP_LINK_ANDROID_APP || "#"),
  showSpidButton: getValueApp("showSpidButton", yn(process.env.REACT_APP_SPID_LOGIN || false)),
  displayAnonymousLogin: getValueApp("displayAnonymousLogin", yn(process.env.REACT_APP_ANONYMOUS_ACCESS || false)),
  ambiente: getValueApp("ambiente", process.env.REACT_APP_AMBIENTE || null),
  showPoweredBy: getValueApp("showPoweredBy", yn(process.env.REACT_APP_SHOW_POWEREDBY || false)),
  hideDashboard: getValueApp("hideDashboard", yn(process.env.REACT_APP_HIDE_DASHBOARD || false)),
  fileNameExportCSV: getValueApp("fileNameExportCSV", process.env.REACT_APP_DATAGRID_EXPORT_CSV || document.title)
};

export const topBarConfig = {
  hideTopbar: getValueTopBar("hideTopbar", yn(process.env.REACT_APP_HIDE_TOPBAR || false)),
  accountSection: getValueTopBar("accountSection", yn(process.env.REACT_APP_ACCOUNT_SECTION || true)),
  logoutSection: getValueTopBar("logoutSection", yn(process.env.REACT_APP_LOGOUT_SECTION || true)),
  settings: getValueTopBar("settings", yn(process.env.REACT_APP_SETTINGS_VISIBLE || false)),
  displayTopbarNotifications: getValueTopBar("displayTopbarNotifications", yn(process.env.REACT_APP_DISPLAY_TOPBAR_NOTIFICATION || true)),
  displayTopbarSearch: getValueTopBar("displayTopbarSearch", yn(process.env.REACT_APP_DISPLAY_TOPBAR_SEARCH || true)),
  displaySelectLanguages: getValueTopBar("displaySelectLanguages", yn(process.env.REACT_APP_SELECT_LANGUAGE || false)),

}
