import { Fragment, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import axiosFE from 'axios';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Search as SearchIcon, XCircle as XIcon } from 'react-feather';
import { useHistory } from 'react-router';
// import { Wait } from '../../DashboardLayout/Wait';
import { Tip } from '../Tip';
import { makeStyles, useTheme } from '@mui/styles';
import { appConfig } from 'src/config';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
const customPath = appConfig.customPath

const baseurlRest = appConfig.restPrefix;
const instance = axiosFE.create({
  baseURL: baseurlRest,
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
    'Access-Control-Expose-Headers': baseurlRest,
    jwt: window.localStorage.getItem('accessToken')
  }
});

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  },
  icon: {
    color: theme.palette.appBarText.main
  }
}));

export const Search = (props) => {
  const { onClose, open, ...other } = props;
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [msg, setMsg] = useState('');
  const theme = useTheme();
  const history = useHistory();
  const [displayButton, setDisplayButton] = useState(false)

  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      setDisplayButton(true);
    }
    setOpen(false)
  }, [location.pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLink = (link) => {
    history.push(`${customPath}/app/` + link)
  }

  const handleSearch = async (event) => {
    event.preventDefault()
    if (value != "" && value != " ")
      try {
        setLoading(true);
        const response = await instance.get('/procs/search/', {
          params: {
            text: value
          },
          headers: {
            jwt: window.localStorage.getItem('accessToken')
          }
        });
        const lista = [];
        response.data.response.map(e => {
          lista.push({ title: e.title, body: e.body, linkText: e.linkText, link: e.link, category: e.category });
        });
        if (lista.length == 0) {
          setMsg(`${t('noResults')} "${value}"`);
        }
        setResults(lista);
      } catch (err) {
        console.error(err);
        toast.error(t('error'));
      } finally {
        setLoading(false);
      }
  };

  return (
    <>
      <Tooltip title={t('ricerca')} >
        <IconButton
          className={classes.icon}
          onClick={handleOpen}
          size="large"
        >
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={onClose}
        open={isOpen}
        {...other}>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            display: 'flex',
            justifyContent: 'space-between',
            px: 3,
            py: 2,
            fontWeight: 'bold',
          }}
        >
          <Typography variant='h5' sx={{ fontSize: "20px" }}>
            {t('searchTitle')}
          </Typography>
          <IconButton
            color="inherit"
            onClick={handleClose}
          >
            <XIcon fontSize="small" />
          </IconButton>
        </Box>
        <DialogContent>
          <form onSubmit={handleSearch}>
            <Tip message={t('searchTip')} />
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                )
              }}
              label={t('searchTitle')}
              placeholder={t('searchPlaceholder')}
              sx={{ mt: 3, pb: 2 }}
              onChange={(event) => setValue(event.target.value)}
              value={value}
            />
            {displayButton ?
              <Button
                variant="contained"
                backgroundColor="primary"
                sx={{ pb: 1 }}
                onClick={handleSearch}>
                {t('searchTip')}
              </Button> : null}
          </form>
          {isLoading ? (
            <Box display="flex" justifyContent="center">
              /<CircularProgress />
            </Box>
          ) : (
            <>
              {results.length != 0 ? (
                results.map((result, i) => (
                  <Box>
                    <div key={i}>
                      <Typography
                        sx={{ my: 2 }}
                        variant="h6"
                      >
                        {result.category}
                      </Typography>
                      <Box
                        onClick={() => handleLink(result.link)}
                        sx={{
                          borderColor: 'divider',
                          borderRadius: 1,
                          borderStyle: 'solid',
                          borderWidth: 1,
                          cursor: 'pointer',
                          '&:hover': {
                            boxShadow: 2
                          }
                        }}
                      >
                        <Fragment key={result.title}>
                          <Box sx={{ p: 2 }}>
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex'
                              }}
                            >
                              <Badge
                                color="primary"
                                sx={{ ml: 1 }}
                                variant="dot"
                              />
                              <Typography
                                variant="subtitle1"
                                sx={{ ml: 2 }}
                              >
                                {result.title}
                              </Typography>
                            </Box>
                            <Typography
                              color="textSecondary"
                              variant="body2"
                            >
                              {ReactHtmlParser(result.body)}
                            </Typography>
                          </Box>
                        </Fragment>
                      </Box>
                    </div>

                  </Box>

                ))
              ) : (
                <Box mb={2}>
                  <Typography variant="body2" color="textPrimary">
                    {msg}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </DialogContent >
      </Dialog >
    </>
  );
};

Search.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default Search;