
import React, { useState, useRef } from 'react';
import {
    Box,
    IconButton,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { LanguagePopover } from './LanguagePopover';

const languages = {
    en: '/static/icons/uk_flag.svg',
    it: '/static/icons/it_flag.svg',
    fr: '/static/icons/fr_flag.svg',
    ar: '/static/icons/ar_flag.svg',
    pu: '/static/icons/pu_flag.svg',
    de: '/static/icons/de_flag.svg',
    es: '/static/icons/es_flag.svg',

};
const LanguageButton = () => {
    const anchorRef = useRef(null);
    const { i18n } = useTranslation();
    const [openPopover, setOpenPopover] = useState(false);

    const handleOpenPopover = () => {
        setOpenPopover(true);
    };

    const handleClosePopover = () => {
        setOpenPopover(false);
    };

    return (
        <>
            <IconButton
                onClick={handleOpenPopover}
                ref={anchorRef}
                sx={{ ml: 1 }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        height: 20,
                        width: 20,
                        '& img': {
                            width: '100%'
                        }
                    }}
                >
                    <img
                        alt=""
                        src={languages[i18n.language]}
                    />
                </Box>
            </IconButton>
            <LanguagePopover
                anchorEl={anchorRef.current}
                onClose={handleClosePopover}
                open={openPopover}
            />
        </>
    );
};

export default LanguageButton