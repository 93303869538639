import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

const TipRoot = styled('div')((({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark'
    //     ? theme.palette.background.dark
    //     : theme.palette.background.dark,
    backgroundColor: theme.palette.background.dark,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    padding: theme.spacing(1)
})));

export const Tip = (props) => {
    const { message } = props;

    return (
        <TipRoot>
            <LightbulbIcon
                sx={{
                    color: 'text.secondary',
                    mr: 1
                }}
                fontSize="small"
            />
            <Typography
                color="textSecondary"
                sx={{
                    '& span': {
                        fontWeight: 700
                    }
                }}
                variant="caption"
            >
                <span>
                    Tip.
                </span>
                {' '}
                {message}
            </Typography>
        </TipRoot>
    );
};

Tip.propTypes = {
    message: PropTypes.string.isRequired
};
